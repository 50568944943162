import { Controller } from '@hotwired/stimulus';
import SlimSelect from 'slim-select';

export default class HasManyExercicesController extends Controller {
  static targets = [
    'addExerciceSelect',
    'exerciceTemplate',
    'exercicePlaceholder',
  ];

  connect() {}

  addExerciceSelectTargetConnected(el) {
    this.addExerciceSelect = new SlimSelect({
      select: el,
      settings: {
        placeholderText: 'Adicionar exercício...',
        searchText: 'Nenhum resultado encontrado...',
        searchPlaceholder: 'Pesquise...',
      },
      events: {
        afterChange: (newVal) => {
          const selectedOption = newVal[0];

          if (!!selectedOption?.value) {
            this.addExercice({ id: selectedOption.value, nome: selectedOption.text });
          }

          this.addExerciceSelect.setSelected('');
        },
      },
    });
  }

  addExerciceSelectTargetDisconnected(el) {
    this.addExerciceSelect?.destroy();
  }

  addExercice(exercice) {
    const newName = new Date().getTime().toString();
    let newHTML = this.exerciceTemplateTarget.innerHTML.replace(/NEW_RECORD/g, newName);

    newHTML = newHTML.replace(/\$exercicio_id\$/g, exercice.id);
    newHTML = newHTML.replace(/\$exercicio_nome\$/g, exercice.nome);

    this.exercicePlaceholderTarget.insertAdjacentHTML('beforebegin', newHTML);
  }

  removeExercice(e) {
    e.preventDefault();

    const wrapper = e.target.closest('.has-many-exercices-exercice-wrapper');

    if (wrapper.dataset.newRecord === 'true') {
      wrapper.remove();
    } else {
      wrapper.style.display = 'none';
      const destroyInput = wrapper.querySelector("input[name*='_destroy']");
      destroyInput.value = '1';
    }
  }
}
