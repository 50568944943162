import { Controller } from '@hotwired/stimulus';

export default class StudioHorariosController extends Controller {
  static targets = ['table'];

  connect() {
    this.tableTarget.querySelectorAll('td').forEach((cell) => this.setCellColor(cell));
  }

  toggleHorario(e) {
    this.tableTarget.querySelectorAll(`[data-horario="${e.target.value}"]`).forEach((cell) => {
      cell.dataset.horarioChecked = e.target.checked;
      this.setCellColor(cell);
    });
  }

  toggleMinuto(e) {
    const checkedQuery = e.target.checked ? ':not(:checked)' : ':checked';
    this.tableTarget
      .querySelectorAll(`input[data-minuto="${e.target.value}"]${checkedQuery}`)
      .forEach((input) => input.click());
  }

  toggleWday(e) {
    this.tableTarget.querySelectorAll(`[data-wday="${e.target.value}"]`).forEach((cell) => {
      cell.dataset.wdayChecked = e.target.checked;
      this.setCellColor(cell);
    });
  }

  setCellColor(cell) {
    const { wdayChecked, horarioChecked } = cell.dataset;

    if (wdayChecked == 'true' && horarioChecked == 'true') {
      cell.classList.add('bg-lime-600');
    }

    if (wdayChecked != 'true' && horarioChecked != 'true') {
      cell.classList.remove('bg-lime-600');
      cell.classList.remove('border-green-500');
    }

    if (wdayChecked == 'true' || horarioChecked == 'true') {
      cell.classList.add('border-green-500');
    }

    if (wdayChecked != 'true' || horarioChecked != 'true') {
      cell.classList.remove('bg-lime-600');
    }
  }
}
