import { Controller } from '@hotwired/stimulus';

export default class ViaCepController extends Controller {
  static classes = ['loading'];

  static targets = ['cep', 'rua', 'numero', 'compl', 'bairro', 'uf', 'cidade', 'cidade_uf'];

  allInputs() {
    return [
      this.cepTarget,
      this.ruaTarget,
      this.complTarget,
      this.bairroTarget,
      this.ufTarget,
      this.cidadeTarget,
    ];
  }

  disableInputs() {
    this.allInputs().forEach((i) => {
      i.disabled = true;
      i.classList.add(...this.loadingClasses);
    });
  }

  enableInputs() {
    this.allInputs().forEach((i) => {
      i.disabled = false;
      i.classList.remove(...this.loadingClasses);
    });
  }

  clear() {
    this.allInputs().forEach((i) => (i.value = ''));
    this.cidade_ufTarget.value = '';
  }

  search() {
    this.disableInputs();
    const cleanCEP = this.cepTarget.value.replace(/\D/g, '');

    if (cleanCEP.length != 8) {
      this.clear();
      this.enableInputs();
      return;
    }

    fetch(`https://viacep.com.br/ws/${cleanCEP}/json/`)
      .then((r) => r.json())
      .then((result) => {
        result.erro ? this.clear() : this.autoComplete(result);
      })
      .catch(() => this.clear())
      .finally(() => {
        this.enableInputs();
      });
  }

  autoComplete({ logradouro, complemento, bairro, localidade, uf }) {
    this.ruaTarget.value = logradouro;
    this.complTarget.value = complemento;
    this.bairroTarget.value = bairro;
    this.ufTarget.value = uf;
    this.cidadeTarget.value = localidade;
    this.cidade_ufTarget.value = `${localidade} - ${uf}`;
    this.numeroTarget.focus();
  }
}
