import { Controller } from '@hotwired/stimulus';
import SlimSelect from 'slim-select';

export default class AulaFormController extends Controller {
  static targets = [
    'addExerciceSelect',
    'exerciceTemplate',
    'exercicePlaceholder',
    'alunoSelect',
    'studioSelect',
    'pastAulasFrame',
    'exercicioExecutado',
  ];

  addExerciceSelectTargetConnected(el) {
    this.addExerciceSelect = new SlimSelect({
      select: el,
      settings: {
        placeholderText: 'Adicionar exercício...',
        searchText: 'Nenhum resultado encontrado...',
        searchPlaceholder: 'Pesquise...',
      },
      events: {
        afterChange: (newVal) => {
          const selectedOption = newVal[0];

          if (!!selectedOption?.value) {
            this.addExercice({ id: selectedOption.value, nome: selectedOption.text }, 0);
          }

          this.addExerciceSelect.setSelected('');
        },
      },
    });
  }

  addExerciceSelectTargetDisconnected(el) {
    this.addExerciceSelect?.destroy();
  }

  alunoSelectTargetConnected(el) {
    this.alunoSelect = new SlimSelect({
      select: el,
      settings: {
        placeholderText: 'Escolha um aluno...',
        searchText: 'Nenhum resultado encontrado...',
        searchPlaceholder: 'Pesquise...',
        allowDeselect: false,
      },
      events: {
        afterChange: (newVal) => newVal[0] && this.alunoSelected(newVal[0]),
      },
    });
  }

  alunoSelected(el) {
    this.reloadPastAulasOption(el.value);

    const alunoStudio = el.data.studioId;
    if (alunoStudio && this.studioSelect) this.studioSelect.setSelected(alunoStudio);
  }

  alunoSelectTargetDisconnected(el) {
    this.alunoSelect?.destroy();
  }

  studioSelectTargetConnected(el) {
    this.studioSelect = new SlimSelect({
      select: el,
      settings: {
        placeholderText: 'Escolha um studio...',
        searchText: 'Nenhum resultado encontrado...',
        searchPlaceholder: 'Pesquise...',
        allowDeselect: false,
        showSearch: false,
      },
    });
  }

  studioSelectTargetDisconnected(el) {
    this.studioSelect?.destroy();
  }

  addExercice(exercise, index) {
    const newName = `${new Date().getTime().toString()}${index}`;
    let newHTML = this.exerciceTemplateTarget.innerHTML.replace(/NEW_RECORD/g, newName);

    newHTML = newHTML.replace(/\$exercicio_id\$/g, exercise.id);
    newHTML = newHTML.replace(/\$exercicio_nome\$/g, exercise.nome);

    this.exercicePlaceholderTarget.insertAdjacentHTML('beforebegin', newHTML);
  }

  copyExercices(e) {
    const exercicies = JSON.parse(e.currentTarget.dataset.exercicesToCopy);
    if (exercicies && !!exercicies.length) {
      exercicies.forEach((ex, index) => this.addExercice(ex, index));
    }
  }

  removeExercice(e) {
    e.preventDefault();

    const wrapper = e.target.closest('.aula-form-exercice-wrapper');

    if (wrapper.dataset.newRecord === 'true') {
      wrapper.remove();
    } else {
      wrapper.style.display = 'none';
      const destroyInput = wrapper.querySelector("input[name*='_destroy']");
      destroyInput.value = '1';
    }
  }

  reloadPastAulasOption(alunoId) {
    if (alunoId) {
      let newUrl = this.pastAulasFrameTarget.src.replace(/\?aluno_id=.+$/, '');
      newUrl = newUrl.concat('?aluno_id=', alunoId);

      this.pastAulasFrameTarget.src = newUrl;
      this.pastAulasFrameTarget.disabled = false;
    } else {
      this.pastAulasFrameTarget.innerHTML = '';
      this.pastAulasFrameTarget.disabled = true;
    }
  }

  inicioChanged(e) {
    const endHourInput = this.element.querySelector('#aula_fim_4i');
    const startHour = parseInt(e.target.value);

    endHourInput.value = String(startHour + 1).padStart(2, '0');
  }

  setAllExercicioExecutadoChanged(e) {
    this.exercicioExecutadoTargets.forEach((ck) => (ck.checked = e.target.checked));
  }
}
