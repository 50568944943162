import { Controller } from '@hotwired/stimulus';
import { patch } from '@rails/request.js';

export default class AgendaController extends Controller {
  static targets = ['chargeAlunosWarning'];
  connect() {
    try {
      const hiddenStudioIds = JSON.parse(localStorage.getItem('OrenFit_HiddenStudioIds'));
      if (hiddenStudioIds) this.hideAllStudios(hiddenStudioIds);
    } catch (e) {}
  }

  filterStudio(e) {
    e.target.checked ? this.showStudio(e.target.value) : this.hideStudio(e.target.value);
  }

  elementsWithStudioId(id) {
    return this.element.querySelectorAll(`[data-studio-id="${id}"]`);
  }

  showStudio(studioId) {
    this.elementsWithStudioId(studioId).forEach((e) => {
      e.classList.remove('hidden');
    });
  }

  hideStudio(studioId) {
    this.elementsWithStudioId(studioId).forEach((e) => {
      e.classList.add('hidden');
    });
  }

  hideAllStudios(studioIds) {
    studioIds.forEach((id) => this.hideStudio(id));
  }

  aulaDragStarted(ev) {
    ev.dataTransfer.dropEffect = 'move';
    ev.dataTransfer.setData('application/aula-id', ev.target.dataset.dragAulaId);
  }

  dragEnteredSlot(ev) {
    ev.preventDefault();
    ev.currentTarget.classList.add('bg-gray-200');
  }

  dragLeftSlot(ev) {
    ev.currentTarget.classList.remove('bg-gray-200');
  }

  async aulaDropped(ev) {
    ev.preventDefault();
    this.dragLeftSlot(ev);

    const aulaId = ev.dataTransfer.getData('application/aula-id');
    const slot = ev.currentTarget;
    const aula = { dropped_at_inicio: `${slot.dataset.day} ${slot.dataset.hour}` };

    patch(`/fit/agenda/aulas/${aulaId}`, {
      body: { aula },
      contentType: 'application/json',
      responseKind: 'turbo-stream',
    });
  }

  chargeAlunosWarningTargetConnected(e) {
    const ids = JSON.parse(e.dataset.alunosAtrasadosIds);
    const selector = ids.map((id) => `[data-warning-to-charge-aluno='${id}']`).join(',');
    document.querySelectorAll(selector).forEach((el) => el.classList.remove('hidden'));
  }
}
