import { Controller } from "@hotwired/stimulus"

export default class ClickerController extends Controller {
  static values = {
    delay: Number,
  }

  connect() {
    const delay = this.hasDelayValue ? this.delayValue : 1000
    this.timeout = setTimeout(() => { this.click(); }, delay)
  }

  disconnect() {
    if (this.timeout) clearTimeout(this.timeout);
  }

  click() {
    this.element.click()
  }
}
