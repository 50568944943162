import { Controller } from '@hotwired/stimulus';

export default class AssistDashboardCurrentTimeController extends Controller {
  static targets = ['display'];

  connect() {
    this.tick();
    this.interval = setInterval(this.tick.bind(this), 1000);
  }

  disconnect() {
    if (this.interval) clearInterval(this.interval);
  }

  tick() {
    this._setTime();
    this._setPosition();
  }

  _setTime() {
    if (!this.hasDisplayTarget) return;

    const currentTime = new Date();
    const htmlString = `${currentTime.getHours()}:${currentTime
      .getMinutes()
      .toString()
      .padStart(2, '0')}`;
    this.displayTarget.innerHTML = htmlString;
  }

  _setPosition() {
    const timerHeight = this.element.getBoundingClientRect().height;
    const viewportHeight = document.documentElement.clientHeight;

    const minHeight = 185;
    const maxHeight = viewportHeight - timerHeight;
    const distance = maxHeight - minHeight;

    const currentMin = new Date().getMinutes();
    const currentProgress = currentMin / 60;
    const currentPosition = distance * currentProgress + minHeight;

    this.element.style.top = `${currentPosition}px`;
  }
}
