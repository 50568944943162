import { Controller } from '@hotwired/stimulus';

export default class AssistDashboardPlayMessageController extends Controller {
  static targets = [
    'audio',
    'description',
    'playButton',
    'pauseButton',
    'stopButton',
    'progressWrapper',
    'progressBar',
    'progressCurrent',
    'progressTotal',
  ];

  // static values = { durationSeconds: Number };

  play() {
    if (this.hasAudioTarget) this.audioTarget.play();

    this.playButtonTarget.classList.add('hidden');
    this.descriptionTarget.classList.add('hidden');
    this.pauseButtonTarget.classList.remove('hidden');
    this.stopButtonTarget.classList.remove('hidden');
    this.progressWrapperTarget.classList.remove('hidden');
  }

  pause() {
    if (this.hasAudioTarget) this.audioTarget.pause();

    this.playButtonTarget.classList.remove('hidden');
    this.pauseButtonTarget.classList.add('hidden');
  }

  stop() {
    if (this.hasAudioTarget) {
      this.audioTarget.pause();
      this.audioTarget.currentTime = 0;
    }

    this.playButtonTarget.classList.remove('hidden');
    this.descriptionTarget.classList.remove('hidden');
    this.pauseButtonTarget.classList.add('hidden');
    this.stopButtonTarget.classList.add('hidden');
    this.progressWrapperTarget.classList.add('hidden');
  }

  audioTargetConnected() {
    this.audioTarget.addEventListener('timeupdate', this._updateProgress.bind(this));
  }

  _updateProgress() {
    const currentTime = this.audioTarget.currentTime;
    const duration = this.audioTarget.duration;

    const progress = (currentTime / duration) * 100;
    this.progressBarTarget.style.width = `${progress}%`;
    this.progressCurrentTarget.textContent = this._formatTime(currentTime);
    this.progressTotalTarget.textContent = this._formatTime(duration);
  }

  _formatTime(time) {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  }
}
