import { Controller } from '@hotwired/stimulus';

export default class AulaTimerController extends Controller {
  static targets = ['timer', 'scroll'];
  static values = { startedIso: String, duration: Number };

  connect(){
    this.tick();
    this.interval = setInterval(this.tick.bind(this), 1000);
  }

  disconnect() {
    if (this.interval) clearInterval(this.interval);
  }

  tick() {
    if (!this.startedIsoValue) return;

    const currentTime = new Date();
    const startedTime = new Date(this.startedIsoValue);
    this.currentDuration = Math.floor((currentTime - startedTime) / 1000);

    this._setTime();
    this._setScrollPosition();
  }

  _setTime() {
    if (!this.hasTimerTarget || !this.currentDuration) return;

    const minutes = Math.floor(this.currentDuration / 60);
    const seconds = this.currentDuration % 60;
    const htmlString = `${minutes}:${seconds.toString().padStart(2, '0')}`;
    this.timerTarget.innerHTML = htmlString;
  }

  _setScrollPosition() {
    if (!this.hasScrollTarget || !this.currentDuration) return;

    const contentHeight = this.scrollTarget.scrollHeight;
    const viewportHeight = this.element.getBoundingClientRect().height;

    const scrollHeight = contentHeight - viewportHeight;
    if (scrollHeight <= 0) return;

    const currentProgress = this.currentDuration / this.durationValue;

    const scrollPosition = scrollHeight * currentProgress;
    this.scrollTarget.scrollTo(0, scrollPosition);
  }
}
