import { Controller } from '@hotwired/stimulus';
import { enter, leave } from 'el-transition';

export default class AppMenuController extends Controller {
  static targets = ['wrapper', 'content', 'overlay'];

  open() {
    enter(this.wrapperTarget);
    enter(this.contentTarget);
    enter(this.overlayTarget);
  }

  async close() {
    leave(this.overlayTarget);
    await leave(this.contentTarget);
    leave(this.wrapperTarget);
  }
}
