import { Controller } from '@hotwired/stimulus';
import { enter, leave } from 'el-transition';

export default class NotificationController extends Controller {
  connect() {
    setTimeout(() => {
      enter(this.element);
    }, 500);
  }

  async close() {
    leave(this.element);
  }
}
