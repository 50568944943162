import { Controller } from '@hotwired/stimulus';

export default class AgendaStudioFilterController extends Controller {
  static targets = ['checkbox'];

  connect() {
    try {
      const savedIds = JSON.parse(localStorage.getItem('OrenFit_HiddenStudioIds'));
      if (savedIds) this.uncheckCheckboxes(savedIds);
    } catch (e) {}
  }

  saveState() {
    localStorage.setItem('OrenFit_HiddenStudioIds', JSON.stringify(this.hiddenIds()));
  }

  hiddenIds() {
    return this.checkboxTargets.filter((ck) => !ck.checked).map((ck) => ck.value);
  }

  uncheckCheckboxes(ids) {
    ids.forEach((id) => {
      const checkbox = this.element.querySelector(`input[type="checkbox"][value="${id}"]`);
      if (checkbox) checkbox.checked = false;
    });
  }
}
