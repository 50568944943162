import { Controller } from '@hotwired/stimulus';

export default class ScrollToController extends Controller {
  static targets = [];

  scroll(event) {
    event.preventDefault();
    const id = this.element.href.split('#')[1]
    document.getElementById(id).scrollIntoView();
  }
}
