import { Controller } from '@hotwired/stimulus';

export default class SubmittingDisabledController extends Controller {
  static classes = ['submitting'];
  static targets = ['submitButton'];

  disable() {
    const disableText = this.submitButtonTarget.dataset.disableWith;

    this.submitButtonTarget.innerHTML = disableText;
    this.submitButtonTarget.disabled = true;
    this.submitButtonTarget.classList.add(this.submittingClass)
  }
}
