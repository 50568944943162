import { Controller } from '@hotwired/stimulus';

export default class ImageFieldPreviewController extends Controller {
  static targets = ['input', 'image'];

  connect() {}

  handleChange(e) {
    const firstSelectedFile = e.target.files[0];
    this.imageTarget.src = URL.createObjectURL(firstSelectedFile);
    this.imageTarget.classList.remove('hidden');
  }

  imageTargetConnected(img) {
    if (img.dataset.attached == 'true') img.classList.remove('hidden');
  }
}
