import { Controller } from '@hotwired/stimulus'
import * as Turbo from "@hotwired/turbo"

export default class TurboStreamRedirectController extends Controller {
  static values = { url: String }

  connect () {
    Turbo.visit(this.urlValue)
  }
}
