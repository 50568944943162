import { Application } from '@hotwired/stimulus';
import { registerControllers } from 'stimulus-vite-helpers';

import { Dropdown, Popover } from 'tailwindcss-stimulus-components';

import PasswordVisibility from 'stimulus-password-visibility';
import TextareaAutogrow from 'stimulus-textarea-autogrow';
import NestedForm from 'stimulus-rails-nested-form';
import Autosave from 'stimulus-rails-autosave';
import Clipboard from 'stimulus-clipboard';
import Chart from 'stimulus-chartjs';

import Lightbox from 'stimulus-lightbox';
import 'lightgallery/css/lightgallery.css';

import Carousel from 'stimulus-carousel';
import 'swiper/css/bundle';

// Start the Stimulus application.
const application = Application.start();

// Controller files must be named *_controller.js.
const controllers = import.meta.globEager('./**/*_controller.js');
registerControllers(application, controllers);

application.register('password-visibility', PasswordVisibility);
application.register('textarea-autogrow', TextareaAutogrow);
application.register('nested-form', NestedForm);
application.register('clipboard', Clipboard);
application.register('lightbox', Lightbox);
application.register('dropdown', Dropdown);
application.register('carousel', Carousel);
application.register('autosave', Autosave);
application.register('popover', Popover);
application.register('chart', Chart);
