import { Controller } from '@hotwired/stimulus';

import CreditCard from 'card-validator';

export default class CreditCardController extends Controller {
  static classes = ['invalid'];
  static targets = ['brand', 'number', 'date'];

  showError() {}

  validateNumber() {
    const number = this.numberTarget.inputmask
      ? this.numberTarget.inputmask.unmaskedvalue()
      : this.numberTarget.value;

    const validation = CreditCard.number(number);

    if (!validation.isPotentiallyValid) {
      this.numberTarget.classList.add(...this.invalidClasses);
    } else {
      this.numberTarget.classList.remove(...this.invalidClasses);
    }

    this.brandTarget.value = validation.card?.type || '';
  }

  validateExp() {
    const validation = CreditCard.expirationDate(this.dateTarget.value);
    if (!validation.isValid) {
      this.dateTarget.classList.add(...this.invalidClasses);
    } else {
      this.dateTarget.classList.remove(...this.invalidClasses);
    }
  }
}
