import { Controller } from '@hotwired/stimulus';

export default class MultipleImageFieldPreviewController extends Controller {
  static targets = ['imageTemplate', 'placeholder', 'input'];

  connect() {}

  remove(e) {
    e.preventDefault();

    const wrapper = e.target.closest('.multiple-image-field-preview-wrapper');

    if (wrapper.dataset.newRecord === 'true') {
      wrapper.remove();
    } else {
      wrapper.style.display = 'none';
      const destroyInput = wrapper.querySelector("input[name*='_destroy']");
      destroyInput.value = '1';
    }
  }

  newImagesAdded(e) {
    this.removeNewRecords();

    for (const file of e.target.files) {
      this.addPreview(file);
    }
  }

  removeNewRecords() {
    this.element.querySelectorAll('[data-new-record="true"]').forEach((el) => el.remove());
  }

  addPreview(file) {
    const newName = new Date().getTime().toString();
    let newHTML = this.imageTemplateTarget.innerHTML.replace(/NEW_RECORD/g, newName);

    let template = document.createElement('div');
    template.innerHTML = newHTML;
    template.querySelector('img').src = URL.createObjectURL(file);

    this.placeholderTarget.insertAdjacentElement('beforeBegin', template.firstElementChild);
  }
}
