import { Controller } from '@hotwired/stimulus';
import { enter, leave } from 'el-transition';

export default class SiteMenuController extends Controller {
  static targets = ['content', 'overlay'];

  open() {
    enter(this.contentTarget);
    enter(this.overlayTarget);
  }

  close() {
    leave(this.contentTarget);
    leave(this.overlayTarget);
  }
}
