import { Controller } from '@hotwired/stimulus';
import SlimSelect from 'slim-select';

export default class SlimSelectController extends Controller {

  connect() {
    const elementOptions = JSON.parse(this.element.dataset?.slimOptions || '{}');
    const addable = (value) => ({ text: value, value });
    const events = elementOptions?.taggable ? { addable } : {};

    this.slimSelect = new SlimSelect({
      select: this.element,
      events,
      settings: {
        placeholderText: 'Escolha...',
        searchText: 'Nenhum resultado encontrado...',
        searchPlaceholder: 'Pesquise...',
        showSearch: false,
        allowDeselect: true,
        ...elementOptions,
      },
    });
  }

  disconnect() {
    this.slimSelect?.destroy();
  }
}
