import { Controller } from '@hotwired/stimulus';
import { get } from "@rails/request.js"

export default class SelectStudentController extends Controller {
  selected(e) {
    const value = e.target.value;

    get(`/fit/recebimentos/escolheu_aluno?aluno=${value}`, {
      responseKind: "turbo-stream",
    })
  }
}
