import { Controller } from '@hotwired/stimulus';
import { enter, leave } from 'el-transition';

export default class ElTransitionController extends Controller {
  static targets = ['el'];

  connect() {
    this.enter();
  }

  enter() {
    this.elTargets.forEach((el) => enter(el));
  }

  leave() {
    this.elTargets.forEach((el) => leave(el));
  }
}
