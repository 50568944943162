import { Controller } from '@hotwired/stimulus';

const RsFormatter = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' });

export default class CheckoutPriceController extends Controller {
  static values = { unitPrice: Number };

  static targets = ['quantity', 'total'];

  connect() {
    this.updateTotal();
  }

  getQuantity() {
    return Number(this.quantityTarget.value || 0);
  }

  addQuantity() {
    this.quantityTarget.value = this.getQuantity() + 1;
    this.updateTotal();
  }

  subtractQuantity() {
    if (this.getQuantity() > 1) this.quantityTarget.value = this.getQuantity() - 1;
    this.updateTotal();
  }

  updateTotal() {
    var quantity = this.getQuantity();

    if (quantity < 1) {
      quantity = 1;
      this.quantityTarget.value = quantity;
    }

    this.totalTarget.innerHTML = RsFormatter.format(quantity * this.unitPriceValue);
  }
}
