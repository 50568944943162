import { Controller } from '@hotwired/stimulus';

export default class ElFilterController extends Controller {
  static targets = ['el'];

  filter(e) {
    const search = e.target.value
    this.elTargets.forEach(el => {
      if(el.dataset.elFilterContent.toLowerCase().includes(search.toLowerCase())) {
        el.classList.remove('hidden')
      } else {
        el.classList.add('hidden')
      }
    })
  }
}
