import { Controller } from '@hotwired/stimulus';

export default class CopyAvaliacaoTemplateController extends Controller {
  static targets = ['content'];

  clearContent() {
    this.contentTarget.value = ""
  }

  setHTML(html) {
    this.contentTarget.editor.setSelectedRange([0, 0])
    this.contentTarget.editor.insertHTML(html)
  }

  apply(e) {
    this.clearContent()

    const templateContent = e.target.querySelector('template')
    this.setHTML(templateContent.innerHTML)
  }
}
