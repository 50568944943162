import { Controller } from '@hotwired/stimulus';
import * as Turbo from '@hotwired/turbo';

export default class RecebimentoFormController extends Controller {
  static targets = ['validadeInput', 'valorPlanoInput', 'descontoInput', 'valorInput'];

  connect() {}

  alunoChanged(e) {
    const aluno_id = e.target.value;
    Turbo.visit(`/fit/recebimentos/new?recebimento[aluno_id]=${aluno_id}`, { frame: 'details' });
  }

  adiantadoChanged(e) {
    this.validadeInputTarget.disabled = !e.target.checked;
  }

  descontoChanged(e) {
    const desconto = parseFloat(e.target.value) || 0;
    const valorPlano = parseFloat(this.valorPlanoInputTarget.value) || 0;
    const newValor = valorPlano - desconto;
    this.valorInputTarget.value = newValor < 0 ? 0 : newValor;
  }

  valorChanged(e) {
    const valor = parseFloat(e.target.value) || 0;
    const valorPlano = parseFloat(this.valorPlanoInputTarget.value) || 0;
    const newDesconto = valorPlano - valor;
    this.descontoInputTarget.value = newDesconto < 0 ? 0 : newDesconto;
  }
}
