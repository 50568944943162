import { Controller } from '@hotwired/stimulus';
import Inputmask from 'inputmask';

export default class InputmaskController extends Controller {
  defaultConfig = {
    clearIncomplete: true,
    skipOptionalPartCharacter: ' ',
  };

  connect() {
    Inputmask({ ...this.defaultConfig, ...this.element.dataset }).mask(this.element);
  }

  disconnect() {
    if (this.element.inputmask) {
      this.element.inputmask.remove();
    }
  }
}
